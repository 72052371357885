import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["subjectableTypeSelect", "subjectableSubTypeSelect"];

  connect() {
    this.subjectableTypeSelectTarget.addEventListener("change", () => {
      this.toggleSubTypeSelect();
    });

    this.toggleSubTypeSelect();
  }

  toggleSubTypeSelect() {
    if (this.subjectableTypeSelectTarget.value === "PipelineProspect") {
      this.subjectableSubTypeSelectTarget.parentNode.classList.remove("hidden");
    } else {
      this.subjectableSubTypeSelectTarget.parentNode.classList.add("hidden");
    }
  }
}
