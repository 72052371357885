import { Controller } from "stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["enabledCheckBox", "toneSelect", "languageSelect", "directiveTextArea"];
  static values = {
    url: String,
  };
  primaryPerson = null;

  connect() {}

  invalidateEmailBody() {
    document.querySelector("#generte-email-body-cover")?.classList.remove("hidden");
  }

  async generateEmailBody() {
    const form = this.element.closest("form");
    const formData = new FormData(form);

    this.invalidateEmailBody();

    await post(this.urlValue, {
      body: formData,
      responseKind: "turbo-stream",
    });
  }
}
